import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import HomeLayout from "../layout/HomeLayout";
import ProtectedRoute from "../layout/ProtectedRoute";
import NotFound from "../pages/NotFound/NotFound";
import Login from "../pages/auth/login";
import Home from "../pages/home";
import AdminLayouts from "../layout/AdminLayout"; // Make sure to import AdminLayouts
import Dashboard from "../admin/pages/dashboard";
import AdminProfile from "../admin/pages/adminPage/profile";
import ChangePassword from "../admin/pages/adminPage/changePassword";
import EditProfile from "../admin/pages/adminPage/editProfile";
import ManageSpace from "../admin/pages/manageSpace";


export default function Router() {
  const isAuthenticated = true; // Replace with your actual authentication logic

  const routes = useRoutes([
    {
      path: "/admins",
      element: (
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          adminRoute={true}
          isAdmin={true}
        >
          <AdminLayouts />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/admins/dashboard" />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        { path: "space", element: <ManageSpace /> },
        { path: "profile", element: <AdminProfile /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "profile/edit", element: <EditProfile /> },
      ],
    },
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/admin", element: <Login /> },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return routes;
}
