import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routers from "./routes";
import { Toaster } from "react-hot-toast";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
      <Routers />
      <Toaster position="top-center" />
    </BrowserRouter>
  );
}

export default App;
