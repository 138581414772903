import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import { baseUrlImage } from "../../../utils/config";

function Header({
  placement,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { auth } = useSelector((state) => state.auth);
  const [auth , setAuth] = useState()

  const handleLogout = () => {
    // dispatch(userLogout());
    toast.success("Logout Successfully !!");
    navigate("/");
  };

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a className="navbar-brand brand-logo" href="/admins/dashboard">
            <img src="/assets/images/logo1.png" alt="logo" />
          </a>
          <a className="navbar-brand brand-logo-mini" href="index.html">
            <img src="/assets/images/logo-mini.svg" alt="logo" />
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button
            className="navbar-toggler navbar-toggler align-self-center sidebar-absolute"
            type="button"
          >
            <span className="mdi mdi-menu"></span>
          </button>
          <ul className="navbar-nav navbar-nav-right">
            {/* <h5 className="nav-link mt-3">MYR 0</h5> */}
            <li className="nav-item nav-profile dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="profileDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="nav-profile-img">
                  <img src={"/assets/images/faces/face1.jpg"} alt="idmage" />
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">{auth?.name}</p>
                </div>
              </a>
              <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown" >
                <Link to={"/admins/profile"} className="dropdown-item"><i className="mdi mdi-cached me-2 text-success"></i> Profile{" "}</Link>
                <Link to={"/admins/profile/edit"} className="dropdown-item"><i className="mdi mdi-cached me-2 text-success"></i> Edit
                  profile{" "}</Link>
                <Link to={"/admins/change-password"} className="dropdown-item"><i className="mdi mdi-cached me-2 text-success"></i> Change Password{" "}</Link>
                <div className="dropdown-divider"></div>
                <Link to={""} onClick={handleLogout} className="dropdown-item"><i className="mdi mdi-logout me-2 text-primary"></i> SignOut{" "}</Link>
              </div>
            </li>
            <li className="nav-item nav-logout d-none d-lg-block">
              <a className="nav-link" href="#" onClick={handleLogout}>
                <i className="mdi mdi-power"></i>
              </a>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    </>
  );
}

export default Header;
