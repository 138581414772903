import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Import your images
import photo1 from '../../assets/mrespaceup1.jpg';
import photo2 from '../../assets/mrespaceup2.jpg';
import photo3 from '../../assets/mrespaceup3.jpg';
import photo4 from '../../assets/mrespaceup4.jpg';

const GalleryContainer = styled.div`
  overflow: hidden;
`;

const AnimatedGallery = styled.div`
  display: flex;
  transition: transform 0.5s ease;
`;

const Photo = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  flex: 0 0 auto;
`;

const PhotoGallery = () => {
  const photoSources = [photo1, photo2, photo3,photo4];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % photoSources.length);
    }, 3000); // Change the duration (in milliseconds) for each photo
    return () => clearInterval(interval);
  }, [photoSources.length]);

  const translateValue = `-${currentIndex * 100}%`;

  return (
    <GalleryContainer>
      <AnimatedGallery style={{ transform: `translateX(${translateValue})` }}>
        {photoSources.map((source, index) => (
          <Photo key={index} src={source} alt={`Photo ${index + 1}`} />
        ))}
      </AnimatedGallery>
    </GalleryContainer>
  );
};

export default PhotoGallery;
