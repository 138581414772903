import React from "react";
import Sidenav from "./Sidenav";
import Header from "./Header";

const Main = ({ children, pageName }) => {
  return (
    <div className="container-scroller">
      <Header />
      <div className="container-fluid page-body-wrapper">
        <Sidenav />
        {children}
      </div>
    </div>
  );
};

export default Main;
