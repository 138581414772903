import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const Sidenav = () => {
  const [auth , setAuth] = useState()
  // const { auth } = useSelector((state) => state.auth);
  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a href="#" className="nav-link">
              <div className="nav-profile-image">
                <img
                  src={"/assets/images/faces/face1.jpg"}
                  alt="idmage"
                />
                <span className="login-status online"></span>
              </div>
              <div className="nav-profile-text d-flex flex-column">
                <span className="font-weight-bold mb-2">{auth?.name}</span>
              </div>
              {/* <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i> */}
            </a>
          </li>

          <NavLink className="nav-item text-decoration-none" to="dashboard">
            <Link className="nav-link" to="/admins/dashboard">
              <span className="menu-title">Dashboard</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </NavLink>
          <NavLink className="nav-item text-decoration-none" to="space">
            <Link className="nav-link" to="/admins/space">
              <span className="menu-title">Manage Space</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </NavLink>
        </ul>
      </nav>
    </>
  );
};

export default Sidenav;
