import React from "react";
import BaseCard from "../../components/baseCard";

const Dashboard = () => {
  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Dashboard"} />
          <h2> I am admin</h2>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
