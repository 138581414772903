import React from "react";
import styled from "styled-components";
import PhotoGallery from "../../Components/PhotoGallery/Index";

const Main = styled.main`
  max-width: 800px;
  width: 100%;
  margin: 80px auto 0;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
`;

// Moved Business Hours and Contact sections
const BusinessHoursSection = styled.div`
  margin: 20px 0;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
`;

const BusinessContact = styled.div`
  margin: 30px 0;
  padding: 30px;
  background-color: #f0f0f0;
  border-radius: 15px;
`;

const Footer = styled.footer`
  margin-top: 40px;
  width: 100%;
  background-color: #e0e0e0;
  padding: 20px;
  text-align: center;
`;

const GoogleMap = () => {
  return (
    <iframe
      title="Google Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3361.4830974082342!2d-82.33217492416374!3d32.593306793351495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f0a5277a284751%3A0x95fcbdd646b01437!2sMoreSpaceUp!5e0!3m2!1sen!2sus!4v1690710269395!5m2!1sen!2sus"
      width="600"
      height="650"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

const Home = () => {
  return (
    <Main>
      <Section>
        <SectionTitle>Welcome to MoreSpaceUp</SectionTitle>
        <Paragraph>
          At MoreSpaceUp, we take pride in delivering self-storage solutions to
          cater to your diverse organizational requirements. Our
          state-of-the-art storage facilities are meticulously designed to meet
          the demands of both your personal and professional life. Whether
          you're looking to declutter your living space, safeguard precious
          possessions, or streamline your office, we have the perfect storage
          answer for you.
        </Paragraph>
        <Paragraph>
          With MoreSpaceUp, you gain access to a new dimension of storage
          excellence, where convenience, security, and accessibility converge
          seamlessly. Our facilities are equipped with state-of-the-art security
          cameras that operate 24/7, ensuring the safety and protection of your
          stored belongings. What's more, our user-friendly online platform
          allows you to effortlessly reserve your storage unit with just a few
          clicks, ensuring a contactless and hassle-free experience. It's our
          commitment to making your storage journey as seamless and secure as
          possible.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>Google Map</SectionTitle>
        <GoogleMap />
      </Section>

      <Section>
        <SectionTitle>Photo Gallery</SectionTitle>
        <PhotoGallery />
      </Section>

      {/* Footer containing Business Hours and Contact sections */}
      <Footer>
        <BusinessHoursSection>
          <h2>Business Hours</h2>
          <p>Monday to Friday: 9 AM - 6 PM</p>
          <p>Saturday: 10 AM - 4 PM</p>
          <p>Sunday: Closed</p>
        </BusinessHoursSection>

        <BusinessContact>
          <h2>Contact</h2>
          <p>(478)-419-3690</p>
          <p>312 S Coleman Street, Swainsboro, GA 30401, USA</p>
        </BusinessContact>
      </Footer>
    </Main>
  );
};

export default Home;
