import React from "react";
import { Box, Stack, TextField  ,Button} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import BaseCard from "../../components/baseCard";
// import { change_Password } from "../../../redux/feature/authSlice";

const validationSchema = yup.object({
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  change_password: yup
    .string("Enter your password")
    .required("Enter your Confirm password")
    .oneOf([yup.ref("password")], "Password does not match"),
});

const ChangePassword = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const  { token } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      password: "",
      change_password: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      // dispatch(change_Password({ values , token}));
      navigate(-1);
    },
  });

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Change Password"} />
          <div class="row justify-content-center">
            <div class="card">
              <div class="card-body">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <Stack spacing={3} sx={{ padding: "30px" }}>
                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "50%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="password"
                          name="password"
                          label="Enter Password"
                          type="password"
                          variant="outlined"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                        />
                      </Box>
                      <Box
                        sx={{ margin: "auto", width: "50%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="change_password"
                          name="change_password"
                          type="password"
                          label="Enter Change Password"
                          variant="outlined"
                          value={formik.values.change_password}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.change_password &&
                            Boolean(formik.errors.change_password)
                          }
                          helperText={
                            formik.touched.change_password &&
                            formik.errors.change_password
                          }
                        />
                      </Box>
                    </Box>
                    <Box display="flex" sx={{ justifyContent: "center" }}>
                      <Box sx={{ mt: 4 }}>
                        <Button
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Update
                        </Button>
                      </Box>
                    </Box>
                  </Stack>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
