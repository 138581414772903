import React from "react";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object().shape({
  email: yup
    .string("Enter your email or username")
    .required("Email or username is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
});

const Login = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // dispatch(Login(values));
      toast.success("Login Successfully");
      navigate("/admins/dashboard");
    },
  });

  return (
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo">
                  <img
                    src="./assets/images/logo1.png"
                    alt=""
                    className="mx-auto d-block"
                  />
                </div>
                <form onSubmit={formik.handleSubmit} className="mt-3 mt-md-5">
                  <div className="form-group">
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Enter Email"
                      variant="outlined"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      label="Enter Password"
                      type="password"
                      variant="outlined"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </div>
                  <div className="mt-3 text-center">
                    <Button variant="contained" size="medium" type="submit" style={{ background:"#459fe9"}}>
                      SIGN IN
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      
      // </div>
    
    // </div>
  );
};

export default Login;
