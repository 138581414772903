import React from 'react';
import { Outlet } from "react-router-dom";
import Main from "../admin/components/layout/Main";


function AdminLayouts(props) {
    return (
        <div>
            <Main>
                <Outlet />
            </Main>
        </div>

    )
}
export default AdminLayouts;