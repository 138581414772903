import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import BaseCard from "../../components/baseCard";
// import { profileEdit } from "../../../redux/feature/authSlice";

const validationSchema = yup.object({
  name: yup.string("Enter your Name").required("Name is required"),
  email: yup.string("Enter Email").email().required("Email type is required"),
  phone: yup.string("Enter Phone No.").required("Phone no. is required"),
  image_points: yup.string("Enter Image points.").required("Image points is required"),
});

const EditProfile = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [auth , setAuth] = useState()

  const formik = useFormik({
    initialValues: {
      name: auth?.name || "",
      email: auth?.email || "",
      phone: auth?.phone || "",
      image: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const myForm = new FormData();
      myForm.set("name", values.name);
      myForm.set("email", values.email);
      myForm.set("phone", values.phone);
      // dispatch(profileEdit({ myForm, token }));
      navigate(-1);
    },
  });

  const handleCoverImageChange = (e) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    formik.setFieldValue("image", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <BaseCard title={"Admin Profile"} />
          <div class="row justify-content-center">
            <div class="card">
              <div class="card-body">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <Stack spacing={3} sx={{ padding: "30px" }}>
                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "50%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="name"
                          name="name"
                          label="Enter Name"
                          variant="outlined"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </Box>
                      <Box
                        sx={{ margin: "auto", width: "50%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Enter Email"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </Box>
                    </Box>

                    <Box display="flex">
                      <Box
                        sx={{ margin: "auto", width: "100%", padding: "10px" }}
                      >
                        <TextField
                          fullWidth
                          id="phone"
                          name="phone"
                          label="Enter Phone no."
                          variant="outlined"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                        />
                      </Box>
                    </Box>
               
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Box display="flex" flexWrap="wrap">
                          {file || auth?.image !== "" ? (
                            <Card
                              sx={{
                                maxWidth: 200,
                                maxHeight: 200,
                                margin: "8px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="140"
                                image={
                                  file 
                                }
                                alt={`User image`}
                              />
                            </Card>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>

                      <Box>
                        <input
                          type="file"
                          onChange={handleCoverImageChange}
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-image-upload"
                        />
                        <label htmlFor="cover-image-upload">
                          <Button
                            variant="contained"
                            component="span"
                            color="secondary"
                          >
                            Profile Image
                          </Button>
                        </label>
                      </Box>
                    </Grid>

                    <Box display="flex" sx={{ justifyContent: "center" }}>
                      <Box sx={{ mt: 4 }}>
                        <Button
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Update
                        </Button>
                      </Box>
                    </Box>
                  </Stack>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
