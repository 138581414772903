import React from "react";
import { Outlet } from "react-router-dom";
import Main from "../Components/layout/Main";
import styled from "styled-components";

const Container = styled.div`
  font-family: Arial, sans-serif;
`;

function HomeLayout(props) {
  return (
    <Container>
      <Main>
        <Outlet />
      </Main>
    </Container>
  );
}
export default HomeLayout;
